.image-lightbox-container {
    position: relative;
}
  
.image-lightbox-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 200px;
    transition: .5s ease;
    backface-visibility: hidden;
    border-radius: 10px;
}
  
.image-lightbox-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
  
.image-lightbox-container:hover .image-lightbox-image {
    opacity: 0.3;
}
  
.image-lightbox-container:hover .image-lightbox-middle {
    opacity: 1;
}
  
.image-lightbox-text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}